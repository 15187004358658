import React, { useLayoutEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import CustomLink from 'components/shared/CustomLink'

// Images
import Veilig from 'img/icon/CM-veilig_werken.inline.svg'
import Productief from 'img/icon/CM-productief_werken.inline.svg'
import Overal from 'img/icon/CM-overal_werken.inline.svg'
import Flexibel from 'img/icon/CM-flexibel_werken.inline.svg'
import Beheer from 'img/icon/CM-beheer.inline.svg'
import SDWan from 'img/icon/CM-SDWan.inline.svg'
import Vast from 'img/icon/vaste-mobiele-telefonie.inline.svg'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'

const StyledCustomLink = styled(CustomLink)`
  &:hover {
    text-decoration: none;
  }
`

const StyledSolutions = styled.div``

const Solution = styled(motion.div)`
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  height: 300px;
  width: 100%;
  border-radius: 20px;

  h2 {
    font-size: ${(props) => props.theme.font.size.ms};
    font-weight: ${(props) => props.theme.font.weight.xl};
    padding-bottom: 20px;
  }

  p {
    font-size: ${(props) => props.theme.font.size.s};
    text-align: center;
    padding: 0 20px;
    margin-bottom: 0;
  }
`

const SolutionRow = styled.div`
  margin-left: -80px;
  margin-right: -80px;

  @media screen and (max-width: 1319px) {
    margin-left: -40px;
    margin-right: -40px;
  }

  @media screen and (max-width: 1039px) {
    margin-left: -10px;
    margin-right: -10px;
  }

  @media screen and (max-width: 991px) {
    margin-left: 0;
    margin-right: 0;
  }
`

const IconContainer = styled.div`
  svg {
    width: 40px;
    height: 40px;
  }
`

const SolutionShadow = styled(motion.div)`
  position: absolute;
  background: ${(props) => props.theme.color.gradient.third};
  z-index: 1;
  border-radius: 23px;
`

const SolutionInner = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  position: relative;
  z-index: 2;
  border-radius: 20px;
`

const Solutions = ({ className, parentId }) => {
  const [ref, isVisible] = useInView({
    threshold: 0.1,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if (isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  const {
    solutions: { edges: solutions },
  } = useStaticQuery(graphql`
    {
      solutions: allWordpressPage(
        filter: { parent_element: { wordpress_id: { in: [110, 2535] } } }
        sort: { fields: date }
      ) {
        edges {
          node {
            title
            slug
            wordpress_id
            parent_element {
              wordpress_id
            }
            path
            acf {
              texts {
                small_description
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StyledSolutions
      ref={ref}
      className={`container ${className ? `${className}` : ``}`}
    >
      <SolutionRow className="row py-lg-5 justify-content-center justify-content-center">
        {solutions
          .filter(({ node }) => node.parent_element.wordpress_id === parentId)
          .map(({ node: solution }, index) => (
            <StyledCustomLink
              className="px-md-3 py-lg-0 py-3 col-sm-12 col-md-6 col-lg-2"
              to={solution.path}
              key={solution.wordpress_id}
            >
              <Solution
                initial="rest"
                whileHover="hover"
                whileTap="stress"
                animate={isVisible || view ? 'init' : 'rest'}
                variants={{
                  init: {
                    opacity: 1,
                    y: 0,
                    transition: {
                      type: 'spring',
                      duration: 0.5,
                      delay: index * 0.1,
                    },
                  },
                  stress: {
                    scale: 0.95,
                  },
                  rest: {
                    opacity: 0.3,
                    y: 50,
                    scale: 1,
                  },
                  hover: {
                    scale: 1.05,
                  },
                }}
              >
                <SolutionInner className="h-100 p-lg-0 p-5 d-flex align-items-center justify-content-center">
                  <div>
                    <IconContainer className="d-flex justify-content-center pb-3">
                      {
                        {
                          'veilig-werken': <Veilig />,
                          'overal-werken': <Overal />,
                          'flexibel-werken': <Flexibel />,
                          'zakelijk-internet': <Overal />,
                          'productief-werken': <Productief />,
                          'vaste-en-mobiele-telefonie': <Vast />,
                          'sd-wan': <SDWan />,
                          'moderne-werkplekken': <Veilig />,
                          'iot': <Overal />,
                          datanetwerk: <Flexibel />,
                          beheer: <Beheer />,
                        }[solution.slug]
                      }
                    </IconContainer>
                    <h2 className="text-center">{solution.title}</h2>
                    {parse(solution.acf.texts.small_description)}
                  </div>
                </SolutionInner>
                <SolutionShadow
                  variants={{
                    rest: {
                      zIndex: -1,
                      opacity: 0,
                      height: 'calc(100% - 4px)',
                      width: 'calc(100% - 4px)',
                      left: '2px',
                      top: '2px',
                    },
                    hover: {
                      opacity: 1,
                      height: 'calc(100% + 8px)',
                      width: 'calc(100% + 8px)',
                      left: '-4px',
                      top: '-4px',
                    },
                  }}
                />
              </Solution>
            </StyledCustomLink>
          ))}
      </SolutionRow>
    </StyledSolutions>
  )
}

export default Solutions
